export default class EndPoints {
  static ROOT_DOMAIN = "reachable.app"
  static API_VERSION = "v1"

  // Staging Configuration
  static STAGING_BASE_ROOT = `https://staging-api.${this.ROOT_DOMAIN}`
  static STAGING_BASE = `${this.STAGING_BASE_ROOT}/${this.API_VERSION}`
  static STAGING_SOCKET_BASE = this.STAGING_BASE_ROOT

  // Production Configuration
  static PRODUCTION_BASE_ROOT = `https://api.${this.ROOT_DOMAIN}`
  static PRODUCTION_BASE = `${this.PRODUCTION_BASE_ROOT}/${this.API_VERSION}`
  static PRODUCTION_SOCKET_BASE = this.PRODUCTION_BASE_ROOT

  // Local Configuration
  static LOCAL_BASE_ROOT = `http://localhost:8000`
  static LOCAL_BASE = `${this.LOCAL_BASE_ROOT}/${this.API_VERSION}`
  static LOCAL_SOCKET_BASE = this.LOCAL_BASE_ROOT

  // Default Configuration (can be overridden based on environment)
  static BASE = this.PRODUCTION_BASE
  static SOCKET_BASE = this.PRODUCTION_SOCKET_BASE

  // static BASE = this.LOCAL_BASE
  // static SOCKET_BASE = this.LOCAL_BASE_ROOT

  // User Accounts and Authentication
  static CSRF_TOKEN = `${this.BASE}/accounts/csrf_token`
  static SIGN_UP = `${this.BASE}/accounts/sign_up`
  static UPDATE_PERSONAL_INFO = `${this.BASE}/accounts/update_account_personal_info`
  static UPDATE_TITLE_IN_WORKSPACE = `${this.BASE}/workspaces/update_title_in_workspace`
  static UPDATE_ACCOUNT_PASSWORD = `${this.BASE}/accounts/update_account_password`
  static UPDATE_ACCOUNT_DETAILS = `${this.BASE}/accounts/update_account_details`
  static UPSERT_CUSTOM_EVENT_DAY = `${this.BASE}/workspaces/:workspace_id/upsert_custom_event`
  static DELETE_CUSTOM_EVENT = `${this.BASE}/workspaces/:workspace_id/custom_events/:eventId`
  static SIGN_IN = `${this.BASE}/accounts/login`
  static LOG_OUT = `${this.BASE}/accounts/logout`
  static DELETE_ACCOUNT = `${this.BASE}/accounts/delete_account`
  static ME = `${this.BASE}/accounts/me`
  static INITIATE_PASSWORD_RESET = `${this.BASE}/accounts/initiate_pwd_reset`
  static FINALIZE_PASSWORD_RESET = `${this.BASE}/accounts/reset_pwd`
  static CONFIRM_PASSWORD_LESS_CONFIRMATION_CODE = `${this.BASE}/accounts/confirm_passwordless_confirmation_code`
  static USER_WORKSPACES = `${this.BASE}/workspaces/user_workspaces`
  // Workspaces
  static DOES_USER_HAVE_WORKSPACES = `${this.BASE}/workspaces/does_user_have_workspaces`
  static NEW_WORKSPACE = `${this.BASE}/workspaces/new_workspace`
  static UPDATE_WORKSPACE_NAME = `${this.BASE}/workspaces/:workSpaceId/update_name`
  static BULK_UPDATE_WORKSPACE_NAMES = `${this.BASE}/workspaces/bulk_workspace_names_update`
  static UPDATE_WORKSPACE_THEME = `${this.BASE}/workspaces/:workSpaceId/update_theme`
  static UPDATE_WORKSPACE_LOGO = `${this.BASE}/workspaces/:workSpaceId/update_logo`
  static UPDATE_WORKSPACE_SETTINGS = `${this.BASE}/workspaces/:workSpaceId/update_settings`
  static DELETE_WORKSPACE = `${this.BASE}/workspaces/:workSpaceId/delete`
  static ARCHIVE_WORKSPACES = `${this.BASE}/workspaces/archive_workspaces`
  static DELETE_WORKSPACES = `${this.BASE}/workspaces/delete_workspaces`
  static CREATE_BULK_WORKSPACES = `${this.BASE}/workspaces/create_bulk_workspaces`
  static CONNECT_BULK_EMAIL_ACCOUNTS = `${this.BASE}/workspaces/:workSpaceId/connect_bulk_email_accounts`
  static WORKSPACE = `${this.BASE}/workspaces`
  // Posts and Content
  static ALL_POSTS = `${this.BASE}/workspaces/:workSpaceId/all_posts`
  static DRAFT_POST = `${this.BASE}/posts/:workSpaceId/draft_post`
  static USER_DRAFT = `${this.BASE}/posts/:workSpaceId/user_draft`
  static USER_AVATAR = `${this.BASE}/accounts/update_user_avatar`
  static ACCOUNT_LOGO = `${this.BASE}/accounts/update_account_logo`
  static UPDATE_LAST_VIEWED_WORKSPACE = `${this.BASE}/accounts/update_last_viewed_workspace`
  static ALL_USER_DRAFTS = `${this.BASE}/posts/:workSpaceId/all_user_drafts`
  static DRAFTED_POSTS = `${this.BASE}/workspaces/:workSpaceId/drafted_posts`
  static POST = `${this.BASE}/posts/:workSpaceId/:post_id`
  static POST_TIME = `${this.BASE}/posts/:workSpaceId/:post_id/update_time`
  static UPDATE_POST = `${this.BASE}/posts/:workSpaceId/:post_id/update`
  static RECURRING_POST = `${this.BASE}/posts/:workSpaceId/recurring_posts/:post_group_identifier`
  static SCHEDULED_POSTS = `${this.BASE}/posts/:workSpaceId/scheduled_posts`
  static PUBLISHED_POSTS = `${this.BASE}/posts/:workSpaceId/published_posts`
  static POPULAR_POSTS = `${this.BASE}/posts/:workSpaceId/popular_posts`
  static ACTIVITIES = `${this.BASE}/workspaces/:workSpaceId/activities`
  static UPSERT_AUDIENCE_GROUP = `${this.BASE}/workspaces/:workSpaceId/upsert_audience_group`
  static AUDIENCE_GROUP_COUNT = `${this.BASE}/workspaces/:workSpaceId/count_audience_groups`
  static AUDIENCE_GROUP_FETCH = `${this.BASE}/workspaces/:workSpaceId/fetch_audience_groups`
  static DELETE_AUDIENCE_GROUP = `${this.BASE}/workspaces/:workSpaceId/delete_audience_group/:group_id`
  static TEAM = `${this.BASE}/workspaces/:workspace_id/team`
  static PENDING_TEAM_INVITES = `${this.BASE}/workspaces/:workspace_id/pending_team_invites`
  static CANCEL_PENDING_TEAM_INVITE = `${this.BASE}/workspaces/:workspace_id/pending_team_invites/:token`
  static INVITE_TEAM_MEMBER = `${this.BASE}/workspaces/:workspace_id/invite_team_member`
  static UPDATE_MEMBER_ROLE = `${this.BASE}/workspaces/:workspace_id/update_member_role`
  static ACCEPT_TEAM_INVITE = `${this.BASE}/workspaces/accept_invite`
  static REMOVE_TEAM_MEMBER = `${this.BASE}/workspaces/:workspace_id/remove_team_member`
  static MY_WORKSPACE_ROLE = `${this.BASE}/workspaces/:workspace_id/my_role`
  static WORKSPACE_OWNER = `${this.BASE}/workspaces/:workspace_id/owner`
  static WORKSPACE_PLATFORMS = `${this.BASE}/workspaces/:workSpaceId/platforms`
  static CONNECTED_ACCOUNTS = `${this.BASE}/workspaces/:workSpaceId/platforms/:identifier/accounts`
  static CONNECTED_PLATFORMS_COUNT = `${this.BASE}/workspaces/:workspace_id/connected_platforms_count`
  static ADD_NEW_WORKSPACE_PLATFORM = `${this.BASE}/workspaces/:workSpaceId/add_new_platform`
  static REMOVE_WORKSPACE_PLATFORM = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/:user_id/remove`
  static SUBREDDIT_FLAIRS = `${this.BASE}/workspaces/:workSpaceId/subreddit_flair/:user_id/:subreddit`
  static REMOVE_CORRUPTED_PLATFORMS = `${this.BASE}/workspaces/:workSpaceId/remove_corrupted_platforms`
  static PLATFORMS_PAGES_AND_GROUPS = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/:user_id/pages_and_groups`
  static CONNECT_PAGE_OR_GROUP = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_groups/:page_or_group_id/:user_id/connect`
  static CONNECT_PAGE_OR_ACCOUNT_FOR_MESSAGING = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_accounts/:page_or_account_id/:user_id/connect_for_messaging`
  static DISCONNECT_PAGE_OR_GROUP = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_groups/:page_or_group_id/:target/:user_id/disconnect`
  static DISCONNECT_PAGE_OR_ACCOUNT_FROM_MESSAGING = `${this.BASE}/workspaces/:workSpaceId/platforms/:platform_identifier/pages_and_accounts/:page_or_account_id/:target/:user_id/disconnect_from_messaging`
  static POST_TO_WORKSPACE = `${this.BASE}/posts/:workSpaceId/new_post`
  static CREATE_NEW_PINTEREST_BOARD = `${this.BASE}/workspaces/:workSpaceId/new_pinterest_board`
  static CREATE_NEW_PINTEREST_BOARD_SECTION = `${this.BASE}/workspaces/:workSpaceId/new_pinterest_board_section`
  static REDEEM_VOUCHER = `${this.BASE}/ltds/credit/redeem_voucher`
  static FETCH_VOURCHERS_BY_ACCOUNT = `${this.BASE}/ltds/account/vouchers`
  static BILL_USER = `${this.BASE}/billing/create-checkout-session`
  static CANCEL_SUBSCRIPTION = `${this.BASE}/billing/cancel_subscription`
  static CANCEL_AI_WRITER_SUBSCRIPTION = `${this.BASE}/billing/cancel_ai_writer_subscription`
  static UPCOMING_POPULAR_EVENTS = `${this.BASE}/recommendations/upcoming_popular_events/:workspace_id`
  static USER_NOTIFICATIONS = `${this.BASE}/notifications`
  static READ_NOTIFICATION = `${this.BASE}/notifications/:id/update_read_status`
  static CLEAR_ALL_NOTIFICATIONS = `${this.BASE}/notifications/clear_all`
  static WORKSPACE_DETAILS_FROM_INVITE_TOKEN = `${this.BASE}/workspaces/workspace_details_from/:invite_token`
  static GET_AVAILABLE_TRENDS = `${this.BASE}/recommendations/trends_available_for/:workspace_id`
  static BULK_CONTENT_UPLOAD = `${this.BASE}/posts/:workSpaceId/bulk_content_upload`
  static VERIFY_NEW_EMAIL_ADDRESS = `${this.BASE}/accounts/verify_email`
  static SUPPORTED_COUNTRIES = `${this.BASE}/recommendations/supported_countries_for/:workspace_id`
  static ADD_NEW_DAYS_LOCATION = `${this.BASE}/recommendations/add_days_locations_to/:workspace_id`
  static REMOVE_LOCATION_FROM_WORKSPACE_DAYS = `${this.BASE}/recommendations/remove_location_from_days/:workspace_id/:location`
  static GENERATE_TEXT = `${this.BASE}/ai_writer/:workSpaceId/generate_text`
  static CHECK_AI_WRITER_USAGE_RESUMPTION = `${this.BASE}/ai_writer/:workSpaceId/check_ai_writer_usage_resumption`
  static SAVED_COPIES = `${this.BASE}/ai_writer/:workSpaceId/saved_copies/:tool`
  static ALL_SAVED_COPIES = `${this.BASE}/ai_writer/:workSpaceId/all_saved_copies`
  static SAVE_COPY = `${this.BASE}/ai_writer/:workSpaceId/save_copy`
  static REMOVE_COPY = `${this.BASE}/ai_writer/:workSpaceId/remove_copy/:copy_id`
  static WORKSPACE_BOTS = `${this.BASE}/bots/:work_space_id/:platform_user_id`
  static REQUEST_FOR_CUSTOMIZED_TELEGRAM_BOT = `${this.BASE}/bots/new_customized_bot_request/:workSpaceId`
  static BOT_REQUESTS = `${this.BASE}/bots/bot_requests`
  static SET_DEFAULT_BOT = `${this.BASE}/bots/set_default_bot/:work_space_id/:platform_user_id`
  static POST_BATCH = `${this.BASE}/posts/:workSpaceId/post_batch/:batch_id`
  static TRANSACTION_HISTORY = `${this.BASE}/transactions/transaction_history`
  static TAGGABLES = `${this.BASE}/tagging/taggables`
  static UPLOAD_NEW_FILE = `${this.BASE}/files/:workSpaceId/upload_new_file`
  static TURN_OFF_ALL_POSTS_AND_CAMPAIGNS = `${this.BASE}/workspaces/:workSpaceId/turn_off_all_posts_and_campaigns`
  static PAUSE_POST = `${this.BASE}/posts/:workSpaceId/:post_id/pause`
  static DELETE_POST_FROM_ONLY_POSTLY = `${this.BASE}/posts/:workSpaceId/:post_id/delete_from_only_postly`
  static DELETE_POST_FROM_ONLY_PUBLISHED_PLATFORMS = `${this.BASE}/posts/:workSpaceId/:post_id/delete_from_only_published_platforms`
  static DELETE_ALL_SAVED_COPIES = `${this.BASE}/ai_writer/:workSpaceId/delete_all_saved_copies`
  static DEFAULT_TIMEZONE_FOR_PUBLISHING = `${this.BASE}/workspaces/:workspace_id/default_time_zone_for_publishing`
  static YOUTUBE_VIDEO_CATEGORIES = `${this.BASE}/workspaces/:workspace_id/:regionCode/youtube_video_categories`
  static PREPROCESS_WORKSPACE_TRANSFER = `${this.BASE}/workspaces/:workSpaceId/init_transfer_to`
  static FINALIZE_WORKSPACE_TRANSFER = `${this.BASE}/workspaces/:workSpaceId/finalize_transfer`
  static TOGGLE_PANEL = `${this.BASE}/workspaces/:workSpaceId/toggle_panel`
  static POST_EMAIL_NOTIFICATIONS = `${this.BASE}/workspaces/:workSpaceId/post_email_notifications`
  static COUNT_POSTS = `${this.BASE}/posts/:workSpaceId/:criteria/count`
  static DELETE_ALL_POSTS = `${this.BASE}/posts/:workSpaceId/delete_posts`
  static POST_CATEGORIES = `${this.BASE}/posts/:workSpaceId/post_categories`
  static NEW_POST_CATEGORY = `${this.BASE}/posts/:workSpaceId/new_post_category`
  static POST_NEW_SCHEDULED_RESOURCE_CLEAN_UP_DATE = `${this.BASE}/accounts/schedule_resources_clean_up`
  static DELETE_POST_CATEGORY = `${this.BASE}/posts/:workSpaceId/post_categories/:categoryId/delete`
  static LINK_SHORTENER = `https://postly.link`
  static SIMILAR_HASHTAGS = `${this.BASE}/hashtags/similar_hashtags/:workSpaceId`
  static TAGGING_MENTION = `http://localhost:8000/get_usernames`
  static GET_GOOGLE_ACCESS_TOKEN = `${this.BASE}/external_image/get_google_drive_access_token`
  static GET_GOOGLE_DRIVE_MEDIAS = `${this.BASE}/external_image/:workSpaceId/get_google_drive_medias`
  static DISCONNECT_GOOGLE_DRIVE = `${this.BASE}/external_image/:workSpaceId/disconnect_google_drive`
  static TAGGABLE_USER_OR_PUBLIC_PAGE = `${this.BASE}/tagging/:workSpaceId/taggable_user_or_public_page`
  static ADMIN_LOG = `${this.BASE}/admin/login`
  static UPDATE_PASSWORD_USING_ADMIN_PRIVILEGES = `${this.BASE}/admin/update_account_password_using_admin_privileges`
  static GET_WORDPRESS_CATEGORIES = `${this.BASE}/external_request/get_curent_categories_for_wordpress`
  static CONNECTED_EMAIL_ACCOUNTS = `${this.BASE}/email_accounts/connected_on/:workSpaceId`
  static GET_UNIFIED_INBOX = `${this.BASE}/email_accounts/:workSpaceId/accounts/inbox/:nextMailListToken`
  static VERIFY_DOMAIN_RECORDS = `${this.BASE}/email_accounts/:workSpaceId/accounts/verify_domain_records`
  static VERIFY_SPECIFIC_EMAILS_DOMAIN_RECORDS = `${this.BASE}/email_accounts/:workSpaceId/accounts/verify_specific_email_domain_records`
  static CAMPAIGNS = `${this.BASE}/campaigns/campaigns_on/:workSpaceId`
  static CREATE_SEQUENCE = `${this.BASE}/workspaces/:workspace_id/sequences/create`
  static SEQUENCES = `${this.BASE}/workspaces/:workspace_id/sequences`
  // Leads
  static LEADS = `${this.BASE}/workspaces/:workSpaceId/leads`
  static UPSERT_LEAD = `${this.BASE}/workspaces/:workSpaceId/upsert_lead/:lead_id`
  static FETCH_LEADS_BY_FILTER = `${this.BASE}/workspaces/:workSpaceId/leads/filter`
  static REMOVE_LEADS = `${this.BASE}/workspaces/:workSpaceId/remove_leads`
  static COUNT_WORKSPACE_LEADS = `${this.BASE}/workspaces/:workSpaceId/total_workspace_leads`
  static COUNT_RECENT_WORKSPACE_LEADS = `${this.BASE}/workspaces/:workSpaceId/total_recent_workspace_leads`
  static LEADS_FROM_GOOGLE_SHEET = `${this.BASE}/campaigns/campaigns_on/:workSpaceId/:campaign_id/google_sheet`
  static UPLOAD_BULK_CSV_LEADS = `${this.BASE}/workspaces/work/:workSpaceId/lists/:listId/bulk`
  static CONNECT_BULK_LEADS = `${this.BASE}/campaigns/campaigns_on/:workSpaceId/:campaign_id/bulk_leads`
  static CAMPAIGN_LEADS = `${this.BASE}/campaigns/campaigns_on/:workSpaceId/:campaign_id/leads`
  static UPDATE_CAMPAIGN_LEADS = `${this.BASE}/campaigns/campaigns_on/:workSpaceId/:campaign_id/update_leads`
  static REMOVE_CAMPAIGN_LEAD = `${this.BASE}/campaigns/campaigns_on/:workSpaceId/:campaign_id/leads/:id/delete`
  // Campaigns
  static SEQUENCE = `${this.BASE}/workspaces/:workspace_id/sequences/:sequence_id`
  static SEQUENCE_LISTS = `${this.SEQUENCE}/lists`
  static SEQUENCE_ACTIVATION_STATUS = `${this.SEQUENCE}/activation_status`
  static TOGGLE_SEQUENCE_CONTACT = `${this.BASE}/workspaces/:workSpaceId/sequences/:sequenceId/contactList/:listId`
  static CUSTOM_PROMPT_TEMPLATES = `${this.SEQUENCE}/custom_prompt_templates`
  static CAMPAIGN = `${this.BASE}/campaigns/campaigns_on/:workSpaceId/:campaign_id`
  static CREATE_CAMPAIGN = `${this.BASE}/campaigns/create_campaign_on/:workSpaceId`
  static CONNECT_EMAIL_ACCOUNT = `${this.BASE}/email_accounts/authenticate_on/:workSpaceId`
  static CONNECTED_EMAIL_ACCOUNT = `${this.BASE}/email_accounts/connected_on/:workSpaceId/accounts/:connected_account_id`
  static WARM_UP_CONNECTED_EMAIL_ACCOUNT = `${this.BASE}/warm_up/enable_warm_up`
  static PROMPTS = `${this.BASE}/prompts`
  static EMAIL_ACCOUNT_SETTINGS_UPDATE = `${this.BASE}/update-signature`
  static LISTS = `${this.BASE}/workspaces/:workSpaceId/lists`
  static LIST_ITEMS = `${this.LISTS}/:listId/items`
  static DELETE_LIST_ITEMS = `${this.LISTS}/:listId/delete_items`
  static UPSERT_LIST = `${this.LISTS}/upsert`
  static BATCH_LIST_DELETE = `${this.LISTS}/batch_delete`
}
